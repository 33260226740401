@import "../_main/transition";

/* SLICK SLIDER */
.slick-slider {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
  &:focus {
  outline: none;
  }
  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
  .slick-loading & {
    visibility: hidden;
  }
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
  [dir="rtl"] & {
    float: right;
  }
  img {
    display: block;
  }
  &.slick-loading img {
    display: none;
  }
  &.dragging img {
    pointer-events: none;
  }
  .slick-initialized & {
    display: block;
  }
  .slick-loading & {
    visibility: hidden;
  }
  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}
.slick-arrow.slick-hidden {
  display: none;
}
/* SLICK SLIDER THEME */
.slick-slider {

  /* SLICK ARROWS */

  .slick-prev,
  .slick-next {
    position: absolute;
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #fff;
    background: transparent;
    text-indent: -9999px;
    transition: all .2s linear;
    transform: translateY(-50%);
    z-index: 1;
    transition: $slow-transition;
    &::before {
      content: '';
      position: absolute;
      top: calc(50% - 10px);
      left: 50%;
      display: block;
      width: 10px;
      height: 10px;
      border: 2px solid transparent;
      border-top-color: #fff;
      border-left-color: #fff;
      transform: rotate(-45deg) translate(0, 0);
      transform-origin: 100% 100%;
      transition: $slow-transition;
    }
    &:hover {
      background: #fff;
    }
  }
  .slick-prev {
    &:hover {
      &::before {
        border-top-color: #000;
        border-left-color: #000;
      }
    }
  }
  .slick-next {
    &::before {
      left: auto;
      right: calc(50% - 5px);
      border-color: transparent;
      border-right-color: #fff;
      border-bottom-color: #fff;
    }
    &:hover {
      &::before {
        border-right-color: #000;
        border-bottom-color: #000;
      }
    }
  }
}
