/*
HAND INPUT PLUGINS'S CODE
 */

/* Link to top */
.link-to-top {
  position: fixed;
  right: 30px;
  bottom: 30px;
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #000;
  opacity: .5;
  transition: all 0s,
              opacity .35s ease-in-out,
              visibility .35s ease-in-out;
  z-index: 10;
  &::before {
    content: '';
    position: absolute;
    top: calc(50% - 2.5px);
    left: calc(50% - 5px);
    display: block;
    width: 10px;
    height: 10px;
    border: 2px solid transparent;
    border-top-color: #fff;
    border-left-color: #fff;
    transform: rotate(45deg);
  }
  &:hover {
    opacity: 1;
  }
  &.is-hide {
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
  }
  &.position-bottom {
    position: absolute;
  }
}
